<template>
    <el-card class="userinfo fontf" style="font-size:16px">
        <el-form :model="user" ref="userForm" label-width="100px" class="user_form">
            <div class="title_c">个 人 信 息</div>
            <el-row>
                <!-- 基本信息 -->
                <el-col :span="12">
                    <el-form-item label="账号：" class="details">
                        {{ this.$store.state.user.username }}
                    </el-form-item>
                    <el-form-item  label="姓名：" class="details">
                        {{ this.$store.state.user.name }}
                    </el-form-item>
                    <el-form-item label="性别：" class="details">
                        {{ this.$store.state.sex[this.$store.state.user.sex] }}
                    </el-form-item>
                    <el-form-item label="职位：" class="details">
                        {{ this.$store.state.user.position_name }}
                    </el-form-item>
                    <el-form-item label="住址：" class="details">
                        {{ this.$store.state.user.address }}
                    </el-form-item>
                    <el-form-item label="入职日期：" class="details">
                        {{ this.$store.state.user.entry_date }}
                    </el-form-item>
                    <el-form-item label="所属企业：" class="details">
                        {{ this.$store.state.user.enterprise_name }}
                    </el-form-item>
                    <el-form-item label="所属部门：" class="details">
                        {{ this.$store.state.user.department_name }}
                    </el-form-item>
                </el-col>
                <!-- 绑定信息 -->
                <el-col :span="12">     
                    <el-form-item label="头像：" class="head_portrait details">
                        <el-avatar v-if="this.$store.state.user.head_portrait" class="portrait " :size="150" fit="unset"
                            :src="user.url" :key="this.$store.state.user.head_portrait"
                            @click.native="$refs.file.click()"></el-avatar>
                        <input type="file" accept="image/png,image/jpeg" hidden ref="file" @change="onFlieChange" />
                        <image-dialog-component :imageDialogVisible="fimageDialogVisible" :newImageUrl="fnewImageUrl"
                            :newImage="fnewImage" @exitImageForm="fimageDialogVisible = false"></image-dialog-component>
                    </el-form-item>
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="微信：" prop="_id" class="details">
                                <div v-if="user.wx_id" class="tag_wx">
                                    <el-tag type="success">已绑定</el-tag>
                                </div>
                                <div v-else class="tag_wx">
                                    <el-tag type="danger">未绑定</el-tag>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div v-if="!user.wx_id" class="div_btn">
                                <el-button type="success" plain @click="addWeChat()">绑定微信</el-button>
                            </div>
                            <wx-dialog-component :ftt="ftt" :wx_url="fwx_url" :wxDialogVisible="fwxDialogVisible"
                                @exitwxDialog="exitwxDialog"></wx-dialog-component>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="邮箱：" prop="email" class="details">
                                <el-input v-model="user.email" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div class="div_btn">
                                <div v-if="user.email" class="div_btn">
                                    <el-button type="primary" plain @click="editEmail()">修改邮箱</el-button>
                                </div>
                                <div v-else class="div_btn">
                                    <el-button type="success" plain @click="editEmail()">绑定邮箱</el-button>
                                </div>
                                <email-dialog-component :emailDialogVisible="femailDialogVisible"
                                    @exitEmailDialog="femailDialogVisible = false"></email-dialog-component>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="手机：" prop="phone" class="details">
                                <el-input v-model="user.phone" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div class="div_btn">
                                <div v-if="user.phone" class="div_btn">
                                    <el-button type="primary" plain @click="editPhone()">修改手机</el-button>
                                </div>
                                <div v-else class="div_btn">
                                    <el-button type="success" plain @click="editPhone()">绑定手机</el-button>
                                </div>
                                <phone-dialog-component :phoneDialogVisible="fphoneDialogVisible"
                                    @exitPhoneDialog="fphoneDialogVisible = false"></phone-dialog-component>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="密码：" prop="password" class="details">
                                <el-input disabled value="****************"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div class="div_btn">
                                <el-button type="primary" plain @click="editPassword()">修改密码</el-button>
                                <password-dialog-component :pwdDialogVisible="fpwdDialogVisible"
                                    @exitPwdDialog="fpwdDialogVisible = false"></password-dialog-component>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-if="!edit_notice">
                        <el-col :span="15">
                            <el-form-item label="通知：" class="details">
                                <div class="tag_wx">
                                    <el-tag v-if="user.notice_wx" type="success">微信通知</el-tag>
                                    <el-tag v-if="user.notice_email" type="success" style="margin-left:30px">邮箱通知
                                    </el-tag>
                                    <el-tag v-if="!(user.notice_wx || user.notice_email)" type="danger">未设置</el-tag>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div class="div_btn">
                                <el-button type="primary" plain @click="edit_notice=true">修改通知</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-else>
                        <el-col :span="15">
                            <el-form-item label="通知：" class="details">
                                <div class="tag_wx">
                                    <el-checkbox v-model="user.notice_wx" label="微信通知" border size="small"
                                        :disabled="!user.wx_id"></el-checkbox>
                                    <el-checkbox v-model="user.notice_email" label="邮箱通知" border size="small"
                                        :disabled="!user.email"></el-checkbox>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <div class="div_btn">
                                <el-button type="success" icon="el-icon-check" plain circle @click="submit_notice()">
                                </el-button>
                                <el-button type="danger" icon="el-icon-close" plain circle @click="exit_notice()">
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script>
import passwordDialogComponent from '@/components/user/MyInformation/passwordDialogComponent'
import emailDialogComponent from '@/components/user/MyInformation/emailDialogComponent'
import imageDialogComponent from '@/components/user/MyInformation/imageDialogComponent'
import phoneDialogComponent from '@/components/user/MyInformation/phoneDialogComponent'
import wxDialogComponent from '@/components/user/MyInformation/wxDialogComponent'

import { we_chat_qr_code_request,we_chat_poll } from '@/network/wx.js'
import { exit_notice_request } from '@/network/user.js'

export default {
    name: 'MyInformation',
    data() {
        return {
            user: {
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
                url:
                    this.$store.state.baseURL +
                    this.$store.state.user.head_portrait,
                wx_id: this.$store.state.user.wx_id,
                notice_wx: this.$store.state.user.notice_wx,
                notice_email:this.$store.state.user.notice_email
            },
            fimageDialogVisible: false,
            fpwdDialogVisible: false,
            femailDialogVisible: false,
            fphoneDialogVisible: false,
            fwxDialogVisible: false,
            fnewImage: '',
            fnewImageUrl: '',
            fwx_url: '',
            ftt: 0,
            edit_notice: false
        }
    },
    methods: {
        onFlieChange() {
            const file = this.$refs.file.files[0]
            this.fnewImage = file
            this.fnewImageUrl = window.URL.createObjectURL(file)
            this.fimageDialogVisible = true
            //解决选择相同图片不触发的问题
            this.$refs.file.value = ''
        },
        editEmail() {
            this.femailDialogVisible = true
        },
        editPhone() {
            this.fphoneDialogVisible = true
        },
        editPassword() {
            this.fpwdDialogVisible = true
        },
        addWeChat() {
            we_chat_qr_code_request({type:'bd'})
                .then((s) => {
                    if (s.status === 0) {
                        this.fwx_url = s.result.url
                        this.fwxDialogVisible = true
                        this.ftt = 120
                        const changeSendWxTime = setInterval(() => {
                            if (this.ftt) {
                                if(this.ftt % 2 === 0) //两秒轮询一次
                                {
                                    we_chat_poll({type:'bd'})
                                        .then((s) => {
                                            if(s.status === 1) {
                                                clearInterval(changeSendWxTime)
                                                this.$alert(
                                                    s.msg,
                                                    '温馨提示',
                                                    {
                                                        confirmButtonText: '确定',
                                                        callback: (action) => {
                                                            this.$store.commit('edit_user_wx_id',s.result.wx_id)
                                                            this.exitwxDialog()
                                                        },
                                                    }
                                                )
                                            } else if(s.status === 2) {
                                                clearInterval(changeSendWxTime)
                                                this.$alert(
                                                    s.msg,
                                                    '温馨提示',
                                                    {
                                                        confirmButtonText: '确定',
                                                        callback: (action) => {
                                                            this.exitwxDialog()
                                                        },
                                                    }
                                                )
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                                this.ftt--
                            } else {
                                clearInterval(changeSendWxTime)
                            }
                        }, 1000)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        delWeChat() {
            this.$message({
                message: '此功能暂未开发完成，敬请期待！',
                type: 'warning'
            });
        },
        exitwxDialog() {
            this.fwxDialogVisible = false
            this.ftt = 0
        },
        exit_notice() {
            this.edit_notice = false
            this.user.notice_wx = this.$store.state.user.notice_wx
            this.user.notice_email = this.$store.state.user.notice_email
        },
        submit_notice() {
            const d = {
                notice_wx: this.user.notice_wx,
                notice_email: this.user.notice_email
            }
            exit_notice_request(d)
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$store.commit('edit_user_notice_wx',d.notice_wx)
                        this.$store.commit('edit_user_notice_email',d.notice_email)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
                .finally(() => this.exit_notice())
        }
    },
    filters: {},
    props: {},
    created() {},
    computed:{},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        passwordDialogComponent,
        emailDialogComponent,
        imageDialogComponent,
        phoneDialogComponent,
        wxDialogComponent,
    },
    watch: {
        '$store.state.user.email'(newVal) {
            this.user.email = newVal
        },
        '$store.state.user.phone'(newVal) {
            this.user.phone = newVal
        },
        '$store.state.user.head_portrait'(newVal) {
            this.user.url = this.$store.state.baseURL + newVal
        },
        '$store.state.user.wx_id'(newVal) {
            this.user.wx_id = newVal
        },
        '$store.state.user.notice_wx'(newVal) {
            this.user.notice_wx = newVal
        },
        '$store.state.user.notice_email'(newVal) {
            this.user.notice_email = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.title_c {
    text-align: center;
    color: #0080FF;
    font-size: 25px;
    margin-bottom: 20px;
}
.fontf{
    font-family: PingFang SC;
    // font-size: 16px;
}
.user_title {
    // text-align: center;
    font-size: 15px;
    font-weight: 20px;
    padding-left: 43px;
    padding-left: 0 0 30px 60px;
}

.head_portrait {
    text-align: center;
}

.portrait {
    cursor: pointer;
}

.div_new_image {
    text-align: center;
}

.new_image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.el-avatar > img {
    width: 150px;
    height: 150px;
}

.div_btn {
    text-align: center;
}

.tag_wx {
    text-align: center;
}
.details>.el-form-item__label {
    color:cornflowerblue;
}
.wx_qr_code {
    width: 150px;
    height: 150px;
}
.grid-content {
    padding-top: 30px;
    background: linear-gradient(150deg,#e2f9f9,#fffef1);
    height: 200px;
    border-radius: 30px;      
    font-size: 15px;
    box-shadow: #dbe9f5 -1px -1px 10px;
}

.year img{
    width: 45px;
    height: 40px;
    vertical-align: middle;
    padding-left: 35px;

}
.tit{
    text-align: center;
    color: #777a80;
    // padding-bottom: 10px;
    img{
        width: 45px;
        height: 45px;
        vertical-align: middle;
        padding-left: 15px;
    }
}
.year {
    color: rgb(72, 77, 82);
    font-size: 16px;
}
.grid-content[data-v-6cb4a75b] {
  font-size: 18px;
}
.userinfo{
    // background: linear-gradient(150deg,#e3fcff,#e7e6e0);
    // background: white;
    background: #e4ebf1;
    height: 840px;
    font-size: 18px;
}
.el-form-item__content{
color: #777a80;
font-size: 18px !important;
}
.blue span{
    color:rgb(4, 174, 241);
}
.red span{
    color:rgb(216, 135, 129);
}
.green span{
    color:rgb(87, 163, 76);
}
.el-form-item__label{
    padding: 0;
    font-size: 17px;
}
.el-avatar > img {
  height: 100%;
}

</style>