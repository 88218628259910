<template>
    <el-dialog
        title="修改邮箱"
        :visible.sync="emailDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeEmailDialog('emailForm')"
    >
        <el-form
            :model="email"
            :rules="rulesEmail"
            ref="emailForm"
            label-width="100px"
        >
            <el-form-item label="登陆密码" prop="password">
                <el-input v-model="email.password" show-password></el-input>
            </el-form-item>
            <el-form-item label="新邮箱" prop="email">
                <el-input v-model="email.email"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="验证码" prop="emailCode">
                        <el-input v-model="email.emailCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <div class="div_btn">
                        <el-button
                            v-if="!sendEmailTime"
                            type="primary"
                            plain
                            @click="sendEmail('emailForm')"
                            >获取</el-button
                        >
                        <el-button v-else type="primary" plain disabled
                            >重发({{ sendEmailTime }})</el-button
                        >
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button plain @click="closeEmailDialog('emailForm')"
                >取消</el-button
            >
            <el-button type="primary" plain @click="submitEmail('emailForm')"
                >确定</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import {
    email_erification_code_request,
    edit_eamil_request,
} from '@/network/user.js'

export default {
    name: 'emailDialogComponent',
    data() {
        return {
            email: {
                password: '',
                email: '',
                emailCode: '',
            },
            rulesEmail: {
                password: [
                    {
                        required: true,
                        message: '请填写登陆密码',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '请填写新邮箱',
                        trigger: 'blur',
                    },
                    {
                        type: 'email',
                        message: '邮箱格式不正确',
                        trigger: 'blur',
                    },
                ],
            },
            sendEmailTime: 0,
        }
    },
    computed: {},
    methods: {
        closeEmailDialog(formName) {
            this.$emit('exitEmailDialog')
            this.$refs[formName].resetFields()
        },
        sendEmail(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if (this.email.email === this.$store.state.user.email) {
                    this.$message.error('新邮箱与原邮箱相同')
                    return
                }
                this.sendEmailTime = 60
                email_erification_code_request(this.email)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('验证码已发送!')
                            const changeSendEmailTime = setInterval(() => {
                                if (this.sendEmailTime) {
                                    this.sendEmailTime--
                                } else {
                                    clearInterval(changeSendEmailTime)
                                }
                            }, 1000)
                        } else {
                            this.$message.error(s.msg)
                            this.sendEmailTime = 0
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.sendEmailTime = 0
                    })
            })
        },
        submitEmail(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.$emit('exitEmailDialog')
                edit_eamil_request(this.email)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$store.commit(
                                'edit_user_email',
                                this.email.email
                            )
                            this.$message.success('修改成功!')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closeEmailDialog(formName))
            })
        },
    },
    filters: {},
    props: {
        emailDialogVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>