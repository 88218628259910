<template>
    <el-dialog
        title="头像预览"
        :visible.sync="imageDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        class="dialog_image"
        :close-on-click-modal="false"
    >
        <div class="div_new_image">
            <img :src="newImageUrl" class="new_image" />
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button plain @click="closeImageDialog()">取消</el-button>
            <el-button plain type="primary" @click="submitImg('imageForm')"
                >确 定</el-button
            >
        </span>
    </el-dialog>
</template>

<script>
import { upload_image } from '@/network/user.js'

export default {
    name: 'imageDialogComponent',
    data() {
        return {}
    },
    computed: {},
    methods: {
        closeImageDialog() {
            this.$emit('exitImageForm')
        },
        submitImg() {
            this.$emit('exitImageForm')
            upload_image(this.newImage)
                .then((s) => {
                    if (s.status === 0) {
                        this.$store.commit(
                            'edit_user_head_portrait',
                            s.result.url
                        )
                        this.$message.success('头像修改成功!')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    filters: {},
    props: {
        imageDialogVisible: Boolean,
        newImageUrl: String,
        newImage: File | String,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>