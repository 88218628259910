<template>
    <el-dialog
        title="修改密码"
        :visible.sync="pwdDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closePwdDialog('passwordForm')"
    >
        <el-form
            :model="pwd"
            :rules="rulesPassword"
            ref="passwordForm"
            label-width="100px"
        >
            <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="pwd.oldPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="pwd.newPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
                <el-input
                    v-model="pwd.confirmPassword"
                    show-password
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button plain @click="closePwdDialog('passwordForm')"
                >取消</el-button
            >
            <el-button
                type="primary"
                plain
                @click="submitPassword('passwordForm')"
                >确 定</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { edit_password_request } from '@/network/user.js'

export default {
    name: 'passwordDialogComponent',
    data() {
        return {
            pwd: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            rulesPassword: {
                oldPassword: [
                    {
                        required: true,
                        message: '请填写旧密码',
                        trigger: 'blur',
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: '请填写新密码',
                        trigger: 'blur',
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: '请填写确认密码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {},
    methods: {
        closePwdDialog(formName) {
            this.$emit('exitPwdDialog')
            this.$refs[formName].resetFields()
        },
        submitPassword(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if (this.pwd.newPassword !== this.pwd.confirmPassword) {
                    this.$message.error('两次新密码不一致')
                    return
                }
                this.$emit('exitPwdDialog')
                edit_password_request(this.pwd)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('修改成功!')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                this.closePwdDialog(formName)
            })
        },
    },
    filters: {},
    props: {
        pwdDialogVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>