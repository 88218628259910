<template>
    <el-dialog
        title="修改手机"
        :visible.sync="phoneDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closePhoneDialog('phoneForm')"
    >
        <el-form
            :model="phone"
            :rules="rulesPhone"
            ref="phoneForm"
            label-width="100px"
        >
            <el-form-item label="登陆密码" prop="password">
                <el-input v-model="phone.password" show-password></el-input>
            </el-form-item>
            <el-form-item label="新手机" prop="phone">
                <el-input v-model="phone.phone"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="验证码" prop="phoneCode">
                        <el-input v-model="phone.phoneCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <div class="div_btn">
                        <el-button
                            v-if="!sendPhoneTime"
                            type="primary"
                            plain
                            @click="sendPhone('phoneForm')"
                            >获取</el-button
                        >
                        <el-button v-else type="primary" plain disabled
                            >重发({{ sendPhoneTime }})</el-button
                        >
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button plain @click="closePhoneDialog('phoneForm')"
                >取消</el-button
            >
            <el-button type="primary" plain @click="submitPhone('phoneForm')"
                >确定</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import {
    phone_erification_code_request,
    edit_phone_request,
} from '@/network/user.js'

export default {
    name: 'phoneDialogComponent',
    data() {
        return {
            phone: {
                password: '',
                phone: '',
                phoneCode: '',
            },
            rulesPhone: {
                password: [
                    {
                        required: true,
                        message: '请填写登陆密码',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请填写新手机号',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
            },
            sendPhoneTime: 0,
        }
    },
    computed: {},
    methods: {
        closePhoneDialog(formName) {
            this.$emit('exitPhoneDialog')
            this.$refs[formName].resetFields()
        },
        sendPhone(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if (this.phone.phone === this.$store.state.user.phone) {
                    this.$message.error('新手机与原手机相同')
                    return
                }
                this.sendPhoneTime = 60
                phone_erification_code_request(this.phone)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('验证码已发送!')
                            const changeSendPhoneTime = setInterval(() => {
                                if (this.sendPhoneTime) {
                                    this.sendPhoneTime--
                                } else {
                                    clearInterval(changeSendPhoneTime)
                                }
                            }, 1000)
                        } else {
                            this.$message.error(s.msg)
                            this.sendPhoneTime = 0
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.sendPhoneTime = 0
                    })
            })
        },
        submitPhone(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.$emit('exitPhoneDialog')
                edit_phone_request(this.phone)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$store.commit(
                                'edit_user_phone',
                                this.phone.phone
                            )
                            this.$message.success('修改成功!')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closePhoneDialog(formName))
            })
        },
    },
    filters: {},
    props: {
        phoneDialogVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>